:root {
  /* root colours and fonts go there */
  /* light grey */
  --color-1: hsl(225.8, 23.5%, 68.2%);

  /* mustard yellow */
  --color-2: hsl(52.5, 65.4%, 47.6%);

  /* cyan */
  --color-3: hsl(167.4, 67.3%, 55.7%);

  /* dark grey */
  --color-4: hsl(224.2, 17.1%, 21.8%);

  /* white */
  --color-5: hsl(0, 0%, 100%);

  /* light blue */
  --color-6: hsl(208, 31%, 77%);

  /* red */
  --color-7: hsl(0, 100%, 39%);

  --title-font: 'Orbitron', sans-serif;
  --info-font: 'Aldrich', sans-serif;

}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.light-theme {
  --background-clr: var(--color-6);
}

.dark-theme {
  --background-clr: var(--color-4);
}

body {
  background-color: var(--background-clr);
  text-align: center;
  margin: 0;
  -webkit-font-smoothing: antiagented;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}
