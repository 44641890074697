@media only screen and (min-width: 769px) {
  /* nav {
    display: flex;
    justify-content: space-evenly;
  } */

}
a {
  display: inline-block;
}

.App {
  min-width: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes globe {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.globe {
  height: 10rem;
  background-image: url(./images/globe.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation-name: globe;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.avatar {
  height: 10rem;
  background-image: url(./images/avatar.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.trophy {
  height: 10rem;
  width: auto;
  background-image: url(./images/trophy.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.skull {
  height: 12rem;
  width: 12rem;
  margin: auto;
  background-image: url(./images/skull.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.alert {
  height: 12rem;
  width: 12rem;
  background-image: url(./images/alert.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.badge {
  height: 12rem;
  width: 12rem;
  background-image: url(./images/badge.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.greyBadge {
  height: 12rem;
  width: 12rem;
  background-image: url(./images/greyBadge.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.theguy {
  height: 15rem;
  width: 15rem;
  margin-right: auto;
  background-image: url(./images/theguy.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.speech-bubble {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0 0 auto;
  height: 15rem;
  width: 15rem;
  /* padding: 0.5rem; */
  background-image: url(./images/speechbubble.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

